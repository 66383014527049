import React from "react"
import { PageHeader } from "../common/pageHeaders"
import PageText from "../common/pageText"
import Form from "./contactForm"

import Stroll from "../svg/contact.svg"

import style from "./contact.module.css"

const Contact = () => {
  return (
    <>
      <section>
        <PageHeader>Let's Connect</PageHeader>
        <div className={style.bodyLayout}>
          <PageText>
            if you havent gotten it by now, then i’ll say it one more time...
          </PageText>

          <PageText>I am always down to collaborate on a project!</PageText>

          <PageText>
            if you would like to hire me I would especially appreciate being
            contacted below
          </PageText>
        </div>
        <br />
        <div className={style.flexBodyLayout}>
          <img
            src={Stroll}
            style={{ margin: `0 25px` }}
            alt="svg of Hector walking listening to music"
          />
          <div>
            <Form />
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
