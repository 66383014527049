import React from "react"
import { Formik, Field, Form } from "formik"
import { navigate } from "@reach/router"
import styles from "./form.module.css"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
const FORM_NAME = "contact"

const ContactForm = () => {
  const handleSubmit = values => {
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": FORM_NAME,
        ...values,
      }),
    })
      .then(() => navigate("/successMessage/"))
      .catch(error => alert(error))
  }
  return (
    <>
      <Formik
        initialValues={{
          email: " ",
          subject: " ",
          message: " ",
        }}
        onSubmit={handleSubmit}
      >
        <Form
          name={FORM_NAME}
          data-netlify="true"
          method="post"
          className={styles.formParent}
        >
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <div className={styles.formItemDiv}>
            <label htmlFor="email">Email</label>
            <Field
              id="email"
              name="email"
              placeHolder="john@doe.com"
              type="email"
            />
          </div>
          <div className={styles.formItemDiv}>
            <label htmlFor="subject">Subject</label>
            <Field id="subject" name="subject" type="text" />
          </div>
          <div className={styles.formItemDiv}>
            <label htmlFor="message">Message</label>
            <Field id="message" name="message" as="textarea" />
          </div>

          <button type="submit" className={styles.formSubmit}>
            Submit
          </button>
        </Form>
      </Formik>
    </>
  )
}

export default ContactForm
