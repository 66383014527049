import React from "react"
import Layout from "../components/layout"
import Contact from "../components/Contact"
import { CONTACT_SEO } from "../config/siteMetaData"

const ContactPage = () => {
  return (
    <Layout {...CONTACT_SEO}>
      <Contact />
    </Layout>
  )
}

export default ContactPage
